import { Text } from '@/atoms';

import type { FunctionComponent } from 'react';
import type { TypeBloqueHProps } from './types';

/**
 * BloqueH
 */
export const BloqueH: FunctionComponent<TypeBloqueHProps> = ({
  title,
  description,
  extendedText,
  titleType,
  titleUppercase,
  titleSize,
  titleColor,
  maxWidthTitle,
  titleAlign,
  descriptionType,
  descriptionUppercase,
  descriptionSize,
  descriptionColor,
  maxWidthDescription,
  descriptionAlign,
  className = '',
}: TypeBloqueHProps) => (
  <div className={`flex flex-col gap-4 ${className}`}>
    {title && (
      <Text
        as={titleType}
        {...((maxWidthTitle || titleColor || titleAlign) && {
          style: {
            ...(titleColor && { color: titleColor }),
            ...(maxWidthTitle && { maxWidth: `${maxWidthTitle}px` }),
            textAlign: titleAlign,
          },
        })}
        className={`u-headline ${titleSize} ${titleUppercase ? 'uppercase' : '!normal-case'}`}
      >
        {title}
      </Text>
    )}

    {description && (
      <Text
        as={descriptionType}
        {...((maxWidthDescription || descriptionColor || descriptionAlign) && {
          style: {
            ...(descriptionColor && { color: descriptionColor }),
            ...(maxWidthDescription && {
              maxWidth: `${maxWidthDescription}px`,
            }),
            textAlign: descriptionAlign,
          },
        })}
        className={`u-body ${descriptionSize} ${descriptionUppercase ? 'uppercase' : 'normal-case'}`}
      >
        {description}
      </Text>
    )}

    {extendedText && (
      <div
        style={{
          textAlign: descriptionAlign,
          ...(maxWidthDescription
            ? {
                maxWidth: `${maxWidthDescription}px`,
              }
            : {
                maxWidth: '100%',
              }),
        }}
        className={`u-body prose ${descriptionSize} ${descriptionUppercase ? 'uppercase' : 'normal-case'} ${className}`}
        dangerouslySetInnerHTML={{ __html: extendedText }}
      />
    )}
  </div>
);

BloqueH.displayName = 'BloqueH';
