import { default as BloqueHTemplate } from '@/organisms/BloqueH';

import type { TypeBloqueHProps } from '@/organisms/BloqueH';

/**
 * BloqueH
 */
const BloqueH = ({
  bloque,
  id,
  className,
}: {
  bloque: TypeBloqueHProps & { bloqueHBackgroundColor?: string };
  id: string;
  className?: string;
}) => (
  <section
    id={id}
    className={`px-4 py-4 md:py-8 ${className ?? ''}`}
    {...(bloque.bloqueHBackgroundColor && {
      style: { backgroundColor: bloque.bloqueHBackgroundColor },
    })}
  >
    <div className="u-wrapper flex flex-col gap-4">
      <BloqueHTemplate {...bloque} />
    </div>
  </section>
);

BloqueH.displayName = 'BloqueH';
export default BloqueH;
